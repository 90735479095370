var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('h3',{staticClass:"mb-3"},[_vm._v("All Customer Support Agent Users")]),(_vm.loading)?_c('div',{staticClass:"py-6"},[_c('table-skeleton')],1):_c('DataTable',{attrs:{"columns":[
        { field: 'first_name', label: 'First Name' },
        { field: 'last_name', label: 'Last Name' },
        {
          field: 'email',
          label: 'email',
        },
        {
          field: 'date_joined',
          label: 'Added',
          formatFn: _vm.createdFormatter,
        },
        { field: 'view', label: 'Action', sortable: false },
      ],"rows":_vm.users,"totalRecords":_vm.totalUsers,"basePaginatorUrl":_vm.baseUserUrl,"detailsRouteName":"user-details"},on:{"fetch-next-page-results":_vm.getUsers}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }